import { http } from "@/utils/http";

// 上传
export function uploadImg(data) {
  return http.request("post", "/admin/upload", {
    data,
    beforeRequestCallback: config => {
      config.headers["Content-Type"] = "application/form-data";
    }
  });
}

// 获取线路对应游戏
export function getPlatAndGame() {
  return http.request("get", "/admin/global/plat/game");
}

// 获取订单信息
export function getOrderMessage() {
  return http.request("get", "/admin/global/message/notice");
}

// 冻结
export function frezz(data) {
  return http.request("post", "/admin/agent/status", { data });
}
