import { defineStore } from "pinia";
import { store, router, resetRouter, routerArrays } from "../utils";
import { login } from "@/api/user";
import { useMultiTagsStoreHook } from "./multiTags";
import { setToken, removeToken } from "@/utils/auth";
import { getOrderMessage } from "@/api/public";

export const useUserStore = defineStore({
  id: "pure-user",
  state: (): any => ({
    // 头像
    avatar: "",
    // 用户名
    username: "",
    // 昵称
    nickname: "",
    // 页面级别权限
    roles: [],
    // 是否勾选了登录页的免登录
    isRemembered: false,
    // 登录页的免登录存储几天，默认7天
    loginDay: 7,
    // 未审核订单数量
    unAudit: 0
  }),
  actions: {
    /** 存储头像 */
    SET_AVATAR(avatar: string) {
      this.avatar = avatar;
    },
    /** 存储用户名 */
    SET_USERNAME(username: string) {
      this.username = username;
    },
    /** 存储昵称 */
    SET_NICKNAME(nickname: string) {
      this.nickname = nickname;
    },
    /** 存储角色 */
    SET_ROLES(roles: Array<string>) {
      this.roles = roles;
    },
    /** 存储是否勾选了登录页的免登录 */
    SET_ISREMEMBERED(bool: boolean) {
      this.isRemembered = bool;
    },
    /** 设置登录页的免登录存储几天 */
    SET_LOGINDAY(value: number) {
      this.loginDay = Number(value);
    },
    /** 登入 */
    async loginByUsername(data) {
      return new Promise((resolve, reject) => {
        login(data)
          .then((res: any) => {
            if (res.code === 200) setToken(res);
            resolve(res);
          })
          .catch(error => {
            reject(error);
          });
      });
    },

    /** 前端登出（不调用接口） */
    logOut() {
      this.username = "";
      this.roles = [];
      removeToken();
      useMultiTagsStoreHook().handleTags("equal", [...routerArrays]);
      resetRouter();
      router.push("/login");
    },
    async getAuditCount() {
      const res: any = await getOrderMessage();
      this.unAudit = res.result.count_message;
    }
    /** 刷新`token` */
    // async handRefreshToken(data) {
    //   return new Promise((resolve, reject) => {
    //     refreshTokenApi(data)
    //       .then(data => {
    //         if (data) {
    //           setToken(data.data);
    //           resolve(data);
    //         }
    //       })
    //       .catch(error => {
    //         reject(error);
    //       });
    //   });
    // }
  }
});

export function useUserStoreHook() {
  return useUserStore(store);
}
